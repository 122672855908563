@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);
body {
  margin: 0px !important;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat";
}

.ant-spin-dot-item {
  background-color: white;
}
.ant-menu-sub.ant-menu-inline {
  background-color: white !important;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #2a9a3e !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6ffea;
}
.ant-menu-item-selected {
  color: #2a9a3e !important;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-item-selected,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #2a9a3e !important; /* Color azul predeterminado para ítems seleccionados o activos */
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #2a9a3e;
}
.ant-menu-light .ant-menu-item:hover a,
.ant-menu-light .ant-menu-item-active a,
.ant-menu-light .ant-menu-item-selected a {
  color: #2a9a3e !important; /* Cambia el texto a verde */
}

.ant-menu-submenu-selected {
  color: #2a9a3e !important;
}
.ant-menu-submenu:hover .ant-menu-submenu-arrow {
  color: #2a9a3e !important; /* Cambia el color del icono de la flecha */
}

.ant-menu-submenu-open .ant-menu-submenu-arrow {
  color: #2a9a3e !important; /* Cambia el color de la flecha si el submenú está abierto */
}

/** buttons

/* Estilo para btnPrimary */
.btnPrimary {
  background-color: #2a9a3e !important; /* Fondo verde */
  color: white !important; /* Texto blanco */
  border: 2px solid #2a9a3e !important; /* Borde verde */
  border-radius: 4px !important; /* Bordes redondeados */
  padding: 10px 20px !important; /* Espaciado interno */
  font-weight: bold !important; /* Texto en negrita */
  display: inline-flex !important; /* Asegura que el contenido esté en línea */
  justify-content: center; /* Centra horizontalmente el contenido */
  align-items: center; /* Centra verticalmente el contenido */
  grid-gap: 8px;
  gap: 8px; /* Espacio entre el ícono y el texto */
  text-align: center; /* Centra el texto */
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.btnPrimary:hover {
  background-color: #218c37 !important; /* Fondo más oscuro al pasar el mouse */
  border-color: #218c37 !important; /* Borde más oscuro */
}

.btnPrimary:active {
  background-color: #1e7a31 !important; /* Fondo más oscuro al hacer clic */
  border-color: #1e7a31 !important; /* Borde más oscuro */
}

/***scroll***/
/* Aplica un scroll extremadamente fino y tenue a todo el documento */
html {
  scrollbar-width: thin; /* Scroll fino en Firefox */
  scrollbar-color: #e0e0e0 transparent; /* Color del scroll más tenue y fondo transparente */
}

/* Scroll personalizado para navegadores Webkit (Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 3px; /* Ancho del scroll (aún más fino) */
  height: 3px; /* Altura del scroll horizontal */
}

::-webkit-scrollbar-track {
  background: transparent; /* Fondo completamente transparente */
}

::-webkit-scrollbar-thumb {
  background: #e0e0e0; /* Color tenue del scroll */
  border-radius: 10px; /* Bordes redondeados para suavidad */
  -webkit-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out; /* Transición rápida para cambios de color */
}

::-webkit-scrollbar-thumb:hover {
  background: #d6d6d6; /* Color ligeramente más oscuro al pasar el mouse */
}

::-webkit-scrollbar-thumb:active {
  background: #c8c8c8; /* Color apenas más oscuro al hacer clic */
}

.cell:hover {
  background-color: "#3F76C6" !important;
}

